<!-- Header Structure -->
<template>
  <header class="header-global bg-primary admin-header">
    <nav
      id="navbar-main"
      class="navbar fixed-top navbar-main bg-primary navbar-expand-lg navbar-transparent navbar-light headroom"
    >
      <div class="container-fluid px-3 spy-2">
        <div id="logo px-3">
          <router-link to="/"
            ><img
              src="/static/xpo_isotipo--white.svg"
              width="80"
              alt=""
              class=''
          /></router-link>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbar_global"
          aria-controls="navbar_global"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="navbar-collapse collapse" id="navbar_global">
          <div class="navbar-collapse-header">
            <div class="row">
              <div class="col-6 collapse-brand">
                <div id="logo">
                  <router-link to="/"
                    ><img
                      src="/static/xpo_isotipo--white.svg"
                      width="80"
                      alt=""
                  /></router-link>
                </div>
              </div>
              <div class="col-6 collapse-close">
                <button
                  type="button"
                  class="navbar-toggler"
                  data-toggle="collapse"
                  data-target="#navbar_global"
                  aria-controls="navbar_global"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                  <span></span>
                </button>
              </div>
            </div>
          </div>
          <ul class="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto">
            <app-menu></app-menu>
          </ul>
          <ul class="navbar-nav" v-if="country && country.flag">
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbar-primary_dropdown_1"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                ><img
                  :src="country.flag"
                  class="shadow-lg mr-1"
                  width="50"
                  :alt="country.iso2"
              /></a>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="navbar-primary_dropdown_1"
                style="height: 500px; overflow-y: scroll"
              >
                <a class="dropdown-item" @click.prevent="getCountry('AR')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/commons/1/1a/Flag_of_Argentina.svg'"
                    :alt="'flag'"
                  />
                  Argentina</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('BR')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/en/0/05/Flag_of_Brazil.svg'"
                    :alt="'flag'"
                  />Brasil</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('BO')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/commons/4/48/Flag_of_Bolivia.svg'"
                    :alt="'flag'"
                  />Bolivia</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('CA')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/en/c/cf/Flag_of_Canada.svg'"
                    :alt="'flag'"
                  />Canadá</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('CL')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/commons/7/78/Flag_of_Chile.svg'"
                    :alt="'flag'"
                  />Chile</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('CO')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/commons/2/21/Flag_of_Colombia.svg'"
                    :alt="'flag'"
                  />Colombia</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('CR')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_Costa_Rica_%28state%29.svg'"
                    :alt="'flag'"
                  />Costa Rica</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('EC')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/commons/e/e8/Flag_of_Ecuador.svg'"
                    :alt="'flag'"
                  />Ecuador</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('SV')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/commons/3/34/Flag_of_El_Salvador.svg'"
                    :alt="'flag'"
                  />El Salvador</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('ES')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg'"
                    :alt="'flag'"
                  />España</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('US')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg'"
                    :alt="'flag'"
                  />Estados Unidos</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('GT')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/commons/e/ec/Flag_of_Guatemala.svg'"
                    :alt="'flag'"
                  />Guatemala</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('HN')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/commons/c/ca/Naval_Ensign_of_Honduras.svg'"
                    :alt="'flag'"
                  />Honduras</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('MX')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/commons/f/fc/Flag_of_Mexico.svg'"
                    :alt="'flag'"
                  />México</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('NI')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/commons/1/19/Flag_of_Nicaragua.svg'"
                    :alt="'flag'"
                  />Nicaragua</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('PA')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/commons/a/ab/Flag_of_Panama.svg'"
                    :alt="'flag'"
                  />Panamá</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('PY')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/commons/2/27/Flag_of_Paraguay.svg'"
                    :alt="'flag'"
                  />Paraguay</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('PE')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Peru.svg'"
                    :alt="'flag'"
                  />Perú</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('PT')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_Portugal.svg'"
                    :alt="'flag'"
                  />Portugal</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('PR')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/commons/2/28/Flag_of_Puerto_Rico.svg'"
                    :alt="'flag'"
                  />Puerto Rico</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('DO')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/commons/9/9f/Flag_of_the_Dominican_Republic.svg'"
                    :alt="'flag'"
                  />República Dominicana</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('UY')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/commons/f/fe/Flag_of_Uruguay.svg'"
                    :alt="'flag'"
                  />Uruguay</a
                >
                <a class="dropdown-item" @click.prevent="getCountry('VE')"
                  ><img
                    class="flag"
                    :src="'https://upload.wikimedia.org/wikipedia/commons/7/7b/Flag_of_Venezuela_%28state%29.svg'"
                    :alt="'flag'"
                  />Venezuela</a
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
  import Menu from 'Components/Menu/Menu.vue'
  export default {
    props: ['changeAvatar'],
    components: {
      appMenu: Menu,
    },
    data() {
      return {
        loading: false,
        country: JSON.parse(localStorage.getItem('selectedCountry')),
        avatar: this.$session.get('userAvatar')
          ? this.$session.get('userAvatar')
          : 'https://servidor-imagenes-likeu.nyc3.digitaloceanspaces.com/user-icon-placeholder.png',
      }
    },
    watch: {
      name(newValue, oldValue) {},
      lastname(newValue, oldValue) {},
      avatar(newValue, oldValue) {},
      isLogged(newValue, oldValue) {},
      changeAvatar(newVale, oldValue) {
        this.$forceUpdate()
      },
    },
    computed: {
      isLogged() {
        if (
          localStorage.getItem('vue-session-key') &&
          JSON.parse(localStorage.getItem('vue-session-key')).tokenSession &&
          JSON.parse(localStorage.getItem('vue-session-key')).tokenSession != ''
        ) {
          return true
        } else {
          return false
        }
      },
      name() {
        if (this.$session.get('tokenSession')) {
          return this.$session.get('userName')
        } else {
          return false
        }
      },
      lastname() {
        if (this.$session.get('tokenSession')) {
          return this.$session.get('userLastname')
        } else {
          return false
        }
      },
    },
    methods: {
      getCountry(countryCode) {
        localStorage.setItem('selectedCountryCode', countryCode)
        if (countryCode) {
          axios
            .get(process.env.VUE_APP_API_DIRECTORY + 'country-by-iso/' + countryCode)
            .then((response) => {
              this.country = response.data
              localStorage.setItem('selectedCountry', JSON.stringify(this.country))
            })
        }
      },
      logout() {
        this.$swal({
          title: `Hasta pronto ` + this.name + ' ' + this.lastname,
          html: `Regresa cuando quieras para seguir disfrutando de nuestros servicios`,
          showCancelButton: false,
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        })
        axios
          .get(process.env.VUE_APP_API_DIRECTORY + 'auth/logout')
          .then(() => {
            this.$session.destroy()
          })
          .catch(() => {
            this.$session.destroy()
          })
          .finally(() => {
            setTimeout(() => {
              if (this.$route.path !== '/') {
                this.$router.push('/')
              } else {
                location.reload()
              }
            }, 4000)
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  // Responsive Variables
  $xlmax-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
  $laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1499px)';
  $desktop-device: 'only screen and (min-width: 992px) and (max-width: 1199px)';
  $tablet-device: 'only screen and (min-width: 768px) and (max-width: 991px)';
  $large-mobile: 'only screen and (max-width: 767px)';
  $small-mobile: 'only screen and (max-width: 575px)';
  $extra-small-mobile: 'only screen and (max-width: 479px)';
  .navbar {
    padding: 0px;
  }
  /*.navbar-main {
		position: relative;
	}*/
  .header-fixed .navbar-main {
    background: #003a56 !important;
    /*position: fixed !important;*/
  }
  .flag {
    height: 25px;
    width: 40px;
    padding-right: 5px;
  }
  #navigation.style-1 ul li a:hover {
    transform: scale(1.1);
  }
  .navbar-transparent .navbar-nav .nav-link:hover,
  .navbar-transparent .navbar-nav .nav-link:focus {
    transform: scale(1.1);
  }
  #logo:hover {
    transform: scale(1.1);
  }
  li {
    list-style: none;
  }

  @media #{$tablet-device} {
    .navbar-collapse.collapsing,
    .navbar-collapse.show {
      padding: 1.5rem;
      border-radius: 0.25rem;
      background: rgb(0, 20, 26, 0.9);
      box-shadow: 0 50px 100px rgb(50 50 93 / 10%), 0 15px 35px rgb(50 50 93 / 15%),
        0 5px 15px rgb(0 0 0 / 10%);
      -webkit-animation: show-navbar-collapse 0.2s ease forwards;
      animation: show-navbar-collapse 0.2s ease forwards;
    }
    #logo {
      margin: auto;
    }
    .navbar-nav {
      display: none;
    }
  }
  @media #{$large-mobile} {
    .navbar-collapse.collapsing,
    .navbar-collapse.show {
      padding: 1.5rem;
      border-radius: 0.25rem;
      background: rgb(0, 20, 26, 0.9);
      box-shadow: 0 50px 100px rgb(50 50 93 / 10%), 0 15px 35px rgb(50 50 93 / 15%),
        0 5px 15px rgb(0 0 0 / 10%);
      -webkit-animation: show-navbar-collapse 0.2s ease forwards;
      animation: show-navbar-collapse 0.2s ease forwards;
    }
    #logo {
      margin: auto;
    }
    .navbar-nav {
      display: none;
    }
  }
</style>
