
export const menus = [
	{
		state: '/',
		name: 'Directorio',
		type: 'sub',
		children: [
			{
				state: '/franquicias',
				name: 'Guía de Franquicias',
				type: 'link',
			},
		
			{
				state: '/asociaciones-franquicias',
				name: 'Asociaciones de Franquicias',
				type: 'link',
			},
			{
				state: '/consultores',
				name: 'Consultores',
				type: 'link',
			},
			{
				state: '/proveedores',
				name: 'Proveedores',
				type: 'link',
			},
			{
				state: '/oferta-inmobiliaria',
				name: 'Oferta inmobiliaria',
				type: 'link',
			},
		]
	},
	{
		state: '/',
		name: 'Expansión',
		type: 'sub',
		children: [
				{
				state: '/franquicias-master',
				name: 'Internacionalización',
				type: 'link',
			},
		],
	},
	{
		state: '/sevices',
		name: 'Servicios',
		type: 'sub',
		children: [
			{
				state: '/#contacto',
				name: 'Asesoría a Inversionistas',
				type: 'link',
			},
			{
				state: '/#cog',
				name: 'Software de Gestión',
				type: 'link',
			},
		]
	},
	{
		state: '/#actualidad',
		name: 'Actualidad',
		type: 'sub',
		children: [
			{
				state: '/blog',
				name: 'blog',
				type: 'link',
			},
			{
				state: '/eventos',
				name: 'Eventos',
				type: 'link',
			},
			{
				state: '/noticias',
				name: 'Noticias',
				type: 'link',
			},
		]
	},
	{
		state: '/#contacto',
		name: 'Contacto',
		type: 'link',
	},
]

